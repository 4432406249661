import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Loader, StyledLoader } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Loader" title="Loader" mdxType="PageTitle" />
    <h2 {...{
      "id": "fixed-position-loader"
    }}>{`Fixed position loader`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <p>(There should be a loader in the middle of the page)</p>\n  <Loader position=\"fixed\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      ThemeWrapper,
      Loader,
      StyledLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <p>(There should be a loader in the middle of the page)</p>
    <Loader position="fixed" mdxType="Loader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "absolutely-positioned-loader"
    }}>{`Absolutely positioned loader`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper style={{ height: 100 }}>\n  <Loader position=\"absolute\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      ThemeWrapper,
      Loader,
      StyledLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        height: 100
      }} mdxType="ThemeWrapper">
    <Loader position="absolute" mdxType="Loader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "contrast-loader"
    }}>{`Contrast loader`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper contrast style={{ height: 100 }}>\n  <Loader contrast position=\"absolute\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      ThemeWrapper,
      Loader,
      StyledLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast style={{
        height: 100
      }} mdxType="ThemeWrapper">
    <Loader contrast position="absolute" mdxType="Loader" />
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "sized-loaders"
    }}>{`Sized loaders`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Flex alignItems=\"center\" direction=\"row\" justifyContent=\"space-around\">\n    <Loader size={24} />\n    <Loader size={40} />\n    <Loader size={60} />\n    <Loader size={82} />\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      ThemeWrapper,
      Loader,
      StyledLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex alignItems="center" direction="row" justifyContent="space-around" mdxType="Flex">
      <Loader size={24} mdxType="Loader" />
      <Loader size={40} mdxType="Loader" />
      <Loader size={60} mdxType="Loader" />
      <Loader size={82} mdxType="Loader" />
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-loader"
    }}>{`Styled loader`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper style={{ height: 100 }}>\n  <StyledLoader color=\"#0060ce\" position=\"absolute\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      ThemeWrapper,
      Loader,
      StyledLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper style={{
        height: 100
      }} mdxType="ThemeWrapper">
    <StyledLoader color="#0060ce" position="absolute" mdxType="StyledLoader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Loader} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      